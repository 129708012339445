import { FC } from 'react'
import { PageTransition } from 'next-page-transitions'
import { useRouter } from 'next/router'
import { GlobalFooter } from '@components/organisms/GlobalFooter'
import { GlobalHeader } from '@components/organisms/GlobalHeader'
import { ToastManager } from '@components/organisms/ToastManager'
import styles from './index.module.scss'

/**
 * サイドバーが不要でHeroがある場合のテンプレート
 */
export const PageWithHeroTemplate: FC = ({ children }) => {
  const router = useRouter()
  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <GlobalHeader isTransparent />
        <PageTransition timeout={300} classNames='page-transition'>
          <PageTransitionInner key={router.route}>{children}</PageTransitionInner>
        </PageTransition>
      </main>
      <GlobalFooter />
      <ToastManager />
    </div>
  )
}

// PageTransitionに直でHTMLとなる要素を渡すとエラーを吐くので、そのエラーを潰す用に噛ませるComponent
const PageTransitionInner: FC = ({ children }) => {
  return <>{children}</>
}
