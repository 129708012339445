import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import CheckCircleSvg from '@/public/images/common/check_circle_icon.svg'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'

type Props = {
  iconNode: ReactNode
  title: string
  texts: string[]
  imgSrc: string
  imgAlt?: string
  isReverse?: boolean
  isIllust?: boolean
}

export const TopPageHeroFeature: FC<Props> = observer(
  ({ iconNode, title, texts, imgSrc, imgAlt = 'Feature', isReverse = false, isIllust = false }) => {
    const { t } = useTranslation(['index'])

    return (
      <div
        className={classNames(styles.container, {
          [styles.reverseContainer]: isReverse,
        })}
      >
        <div
          className={classNames(styles.content, {
            [styles.reverseContent]: isReverse,
          })}
        >
          <div className={styles.heading}>
            <div className={styles.icon}>{iconNode}</div>
            <div className={styles.title}>
              {title.split('\n').map((line) => (
                <span key={line}>
                  {t(line)}
                  <br />
                </span>
              ))}
            </div>
          </div>
          <div className={styles.items}>
            {texts.map((text) => (
              <div className={styles.item} key={text}>
                <div className={styles.checkIcon}>
                  <CheckCircleSvg viewBox='0 0 28 28' />
                </div>
                <div className={styles.text}>{t(text)}</div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={classNames(styles.mockup, {
            [styles.isIllust]: isIllust,
          })}
        >
          <img
            className={classNames(styles.img, {
              [styles.isIllust]: isIllust,
            })}
            src={imgSrc}
            alt={imgAlt}
          />
        </div>
      </div>
    )
  }
)
