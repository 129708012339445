import { FC, ReactElement, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  onClick: () => void
  planName: string
  price: ReactNode
  body?: ReactElement
  isActive?: boolean
}

export const PlanCard: FC<Props> = ({ onClick, planName, price, body, isActive = false }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.activeContainer]: isActive,
      })}
      onClick={onClick}
      aria-hidden='true'
      role='button'
    >
      <p className={styles.name}>{planName}</p>
      <p className={styles.price}>{price}</p>
      {body && <div className={styles.body}>{body}</div>}
    </div>
  )
}
