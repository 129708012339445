import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Trans, i18n, useTranslation } from 'next-i18next'
import { Button2 } from '@components/atoms/Button2'
import { PlanCard } from '@components/molecules/PlanCard'
import { DbPrice, MessageType } from '@/types'
import { ProDbYearlyPlanBulletList } from '@/components/molecules/ProDbYearlyPlanBulletList'
import { ProDbMonthlyPlanBulletList } from '@/components/molecules/ProDbMonthlyPlanBulletList'
import { BasicDbYearlyPlanBulletList } from '@/components/molecules/BasicDbYearlyPlanBulletList'
import { BasicDbMonthlyPlanBulletList } from '@/components/molecules/BasicDbMonthlyPlanBulletList'
import { sanitizeUrl, useStores } from '@/utils'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

type Props = {
  setPlan: Dispatch<SetStateAction<DbPrice>>
  plan: DbPrice
}

export const DbPaymentModalBody1: FC<Props> = ({ setPlan, plan }) => {
  const { t } = useTranslation(['global'])
  const locale = i18n.language
  const { viewer, messages } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const LinkToPayment = async () => {
    setIsLoading(true)
    const output = await viewer.viewer?.createPaymentLinks({ userId: viewer.viewer.id, priceType: plan })
    setIsLoading(false)
    if (!output) {
      messages.add({
        type: MessageType.Error,
        body: t('支払い画面への遷移時にエラーが発生しました。サポートへお問い合わせください。'),
        isTranslated: true,
        isDismissable: true,
        ttl: 5000,
      })
      return
    }
    const link = locale === 'ja' ? `${sanitizeUrl(output.paymentLink)}` : `${sanitizeUrl(output.paymentLink)}?locale=en`
    router.push(link)
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{t('最適なプランを選択しましょう')}</h2>
      <div className={styles.plans}>
        {locale === 'ja' ? (
          <>
            <div className={styles.plan}>
              {/* 一月当たり¥30,250(税込) */}
              <PlanCard
                onClick={() => {
                  setPlan(DbPrice.BASIC_YEARLY_JPY)
                }}
                isActive={plan === DbPrice.BASIC_YEARLY_JPY}
                planName={t('年額基本プラン（円）')}
                price={t('¥363,000/年（税込）')}
                body={<BasicDbYearlyPlanBulletList />}
              />
            </div>
            <div className={styles.plan}>
              {/* 一月当たり¥33,000(税込) */}
              <PlanCard
                onClick={() => {
                  setPlan(DbPrice.BASIC_MONTHLY_JPY)
                }}
                isActive={plan === DbPrice.BASIC_MONTHLY_JPY}
                planName={t('月額基本プラン（円）')}
                price={t('¥396,000/年（税込）')}
                body={<BasicDbMonthlyPlanBulletList />}
              />
            </div>
            <div className={styles.plan}>
              {/* 一月当たり¥50,416.666...(税込) */}
              <PlanCard
                onClick={() => {
                  setPlan(DbPrice.PRO_YEARLY_JPY)
                }}
                isActive={plan === DbPrice.PRO_YEARLY_JPY}
                planName={t('年額プロプラン（円）')}
                price={t('¥605,000/年（税込）')}
                body={<ProDbYearlyPlanBulletList />}
              />
            </div>
            <div className={styles.plan}>
              {/* 一月当たり¥55,000(税込) */}
              <PlanCard
                onClick={() => {
                  setPlan(DbPrice.PRO_MONTHLY_JPY)
                }}
                isActive={plan === DbPrice.PRO_MONTHLY_JPY}
                planName={t('月額プロプラン（円）')}
                price={t('¥660,000/年（税込）')}
                body={<ProDbMonthlyPlanBulletList />}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.plan}>
              {/* 一月当たり$182(incl. tax) */}
              <PlanCard
                onClick={() => {
                  setPlan(DbPrice.BASIC_YEARLY_USD)
                }}
                isActive={plan === DbPrice.BASIC_YEARLY_USD}
                planName={t('年額基本プラン（米ドル）')}
                price={
                  <Trans i18nKey='$2189/year(incl. tax)'>
                    $2,189 USD/year
                    <br />
                    (incl. tax)
                  </Trans>
                }
                body={<BasicDbYearlyPlanBulletList />}
              />
            </div>
            <div className={styles.plan}>
              {/* 一月当たり$199(incl. tax) */}
              <PlanCard
                onClick={() => {
                  setPlan(DbPrice.BASIC_MONTHLY_USD)
                }}
                isActive={plan === DbPrice.BASIC_MONTHLY_USD}
                planName={t('月額基本プラン（米ドル）')}
                price={
                  <Trans i18nKey='$2490/year(incl. tax)'>
                    2,490 USD/year
                    <br />
                    (incl. tax)
                  </Trans>
                }
                body={<BasicDbMonthlyPlanBulletList />}
              />
            </div>
          </>
        )}
      </div>
      <div className={styles.button}>
        <Button2 hierarchy='primary' size='xl' disabled={!plan} isLoading={isLoading} onClick={LinkToPayment}>
          {t('お支払い画面へ')}
        </Button2>
      </div>
    </div>
  )
}
