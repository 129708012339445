import { FC, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { DbPaymentModalBody1 } from '@components/organisms/DbPaymentModalBody1'
import { DbPrice } from '@/types'
import { useStores } from '@/utils'
import styles from './index.module.scss'

type Props = {
  onClose?: () => void
}

export const DbPaymentModal: FC<Props> = observer(({ onClose }) => {
  const { ui } = useStores()
  const [plan, setPlan] = useState<DbPrice>(null)

  const closeModal = () => {
    ui.toggleDbPaymentModal()

    // コールバックがあれば実行
    if (onClose) {
      onClose()
    }
  }

  return (
    <div
      className={classNames(styles.modal, {
        [styles.activeModal]: ui.isDbPaymentModalOpen,
      })}
    >
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.closeButton} onClick={closeModal} aria-hidden='true' role='button'>
            <img src='/images/common/close.svg' alt='Close' className='img-fluid' />
          </div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.logo}>
                <img src='/images/common/logo.svg' alt='TOSHIKA' className='img-fluid' />
              </div>
            </div>
            <div className={styles.body}>
              <DbPaymentModalBody1 setPlan={setPlan} plan={plan} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
