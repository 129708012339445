import { FC } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

type Props = {
  title: string
  isTitleWithSuffix?: boolean
  description?: string
  url?: string
  image?: string
  ogType?: string
  twitterCardType?: string
  noIndex?: boolean
}

export const MyHead: FC<Props> = ({
  title,
  isTitleWithSuffix = true,
  description = '',
  url = process.browser ? window.location.href : '',
  image = '',
  ogType = 'website',
  twitterCardType = 'summary_large_image',
  noIndex = false,
}) => {
  const { t } = useTranslation(['global'])
  const formattedTitle = isTitleWithSuffix ? `${title} - TOSHIKA` : title
  const router = useRouter()
  const { locale } = router
  let ogImage = image
  if (!ogImage) {
    ogImage =
      locale === 'ja'
        ? `${process.env.NEXT_PUBLIC_APP_BASE_URL}/images/common/toshika-ogp-ja.png`
        : `${process.env.NEXT_PUBLIC_APP_BASE_URL}/images/common/toshika-ogp.jpg`
  }
  let fixDescription = description
  if (!fixDescription) {
    fixDescription = t(
      '最適な投資家との出会いを､最速で｡スタートアップに特化した、5,000社以上のVC、CVC、事業会社のデータベース。投資ステージや業界情報など、必要な条件でフィルタリングし、あなたのビジネスに最適な投資家をスムーズに見つけることができます。'
    )
  }

  return (
    <Head>
      <title>{formattedTitle}</title>
      <meta name='description' content={fixDescription} />
      <meta property='og:title' content={formattedTitle} />
      <meta property='og:description' content={fixDescription} />
      <meta property='og:type' content={ogType} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta property='og:site_name' content='TOSHIKA' />
      <meta name='fb:app_id' content='161958535763963' />
      <meta name='twitter:card' content={twitterCardType} />
      <meta name='twitter:domain' content='protocol.ooo' />
      <meta name='twitter:site' content='@protocolooo' />
      <meta name='twitter:url' content={ogImage} />
      <meta name='twitter:title' content={formattedTitle} />
      <meta name='twitter:description' content={fixDescription} />
      <meta name='twitter:image' content={ogImage} />
      {noIndex && <meta name='robots' content='noindex, nofollow' />}
      <link rel='canonical' href={url} />
    </Head>
  )
}
