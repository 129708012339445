import { FC } from 'react'
import { observer } from 'mobx-react'
import { TopPageHeroTopEn } from '@/components/organisms/TopPageHeroTopEn'
import { TopPageHeroFeatureList } from '@/components/organisms/TopPageHeroFeatureList'
import { TopPageHeroCTA } from '@/components/organisms/TopPageHeroCTA'
import { useRouter } from 'next/router'
import { TopPageHeroTopJa } from '@/components/organisms/TopPageHeroTopJa'

export const TopPageHero: FC = observer(() => {
  const router = useRouter()
  const { locale } = router
  return (
    <>
      {locale === 'ja' ? <TopPageHeroTopJa /> : <TopPageHeroTopEn />}
      <TopPageHeroFeatureList />
      <TopPageHeroCTA />
    </>
  )
})
