import { FC } from 'react'
import { observer } from 'mobx-react'
import { Trans } from 'next-i18next'
import SpeedSvg from '@/public/images/common/feature_speed_icon.svg'
import SearchSvg from '@/public/images/common/feature_search_icon.svg'
import CommentSvg from '@/public/images/common/feature_comment_icon.svg'
import SphereSvg from '@/public/images/common/feature_sphere_icon.svg'
import { TopPageHeroFeature } from '@/components/molecules/TopPageHeroFeature'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

export const TopPageHeroFeatureList: FC = observer(() => {
  const router = useRouter()
  const { locale } = router
  return (
    <>
      <div className={styles.container}>
        <div className='container'>
          <div className={styles.content}>
            <div className={styles.heading}>Features</div>
            <div className={styles.text}>
              <Trans i18nKey='index:4つのメリット'>
                TOSHIKAを使う
                <br />
                4つのメリット
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <TopPageHeroFeature
        iconNode={<SpeedSvg viewBox='0 0 48 48' />}
        title={locale === 'ja' ? `“今”投資先を探している\n投資家と出会える` : 'feature title 1'}
        texts={[
          'まさに“今”投資先を求めている5,000社以上の投資家情報を網羅的に収録',
          '専門のリサーチャーが、信頼できる最新データを日々更新',
        ]}
        imgSrc={
          locale === 'ja'
            ? '/images/common/home/top-page-feature1-ja.png'
            : '/images/common/home/top-page-feature1-en.png'
        }
        imgAlt='Feature 1'
      />
      <TopPageHeroFeature
        iconNode={<SearchSvg viewBox='0 0 48 48' />}
        title={locale === 'ja' ? `投資家検索に特化した\n直感的なインターフェース` : 'feature title 2'}
        texts={[
          '対象ラウンド、投資家の種類、対象業界、投資目的など、細かな条件でフィルタリングして投資家を検索可能',
          '投資家のあらゆる情報をひとつのページで一目で確認',
        ]}
        imgSrc={
          locale === 'ja'
            ? '/images/common/home/top-page-feature2-ja.png'
            : '/images/common/home/top-page-feature2-en.png'
        }
        imgAlt='Feature 2'
        isReverse
      />
      <TopPageHeroFeature
        iconNode={<CommentSvg viewBox='0 0 48 48' />}
        title={locale === 'ja' ? `リアルな評判を\n匿名口コミで確認できる` : 'feature title 3'}
        texts={['完全匿名の口コミを通じて、投資家の実際の評判を確認', '投資家選びの判断材料として活用可能']}
        imgSrc={
          locale === 'ja'
            ? '/images/common/home/top-page-feature3-ja.png'
            : '/images/common/home/top-page-feature3-en.png'
        }
        imgAlt='Feature 3'
      />
      <TopPageHeroFeature
        iconNode={<SphereSvg viewBox='0 0 48 48' />}
        title={locale === 'ja' ? `ユーザー限定で投資家\nとの出会いを創出` : 'feature title 4'}
        texts={['投資先を探している投資家とスタートアップが出会える機会を創出', '投資家からの面談オファー機会を提供']}
        imgSrc='/images/common/home/top-page-feature4.png'
        imgAlt='Feature 4'
        isReverse
        isIllust
      />
    </>
  )
})
