import { FC, ReactElement } from 'react'
import styles from './index.module.scss'

type Props = {
  items: ReactElement[]
}

export const BulletList: FC<Props> = ({ items = [] }) => {
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <div key={item.key} className={styles.item}>
          {item}
        </div>
      ))}
    </div>
  )
}
