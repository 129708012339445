import { FC } from 'react'
import { Trans, i18n, useTranslation } from 'next-i18next'
import styles from './index.module.scss'
import { BulletList } from '../BulletList'

export const BasicDbYearlyPlanBulletList: FC = () => {
  const { t } = useTranslation(['global'])
  const locale = i18n.language
  const items = [
    <p key='one'>
      {locale === 'ja' ? (
        t('¥30,250/月（税込）')
      ) : (
        <Trans i18nKey='$182/month(incl. tax)'>
          182 USD/month
          <br />
          (incl. tax)
        </Trans>
      )}
    </p>,
    <p key='two'>{t('月額プランよりお得Basic')}</p>,
  ]
  return (
    <div className={styles.container}>
      <BulletList items={items} />
    </div>
  )
}
