import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { Button2 } from '@/components/atoms/Button2'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

export const TopPageHeroCTA: FC = observer(() => {
  const { t } = useTranslation(['index'])
  return (
    <div className={styles.container}>
      <div className='container'>
        <div className={styles.content}>
          <div className={styles.box}>
            <div className={styles.heading}>
              <div className={styles.title}>
                {t('資金調達のプロが')}
                <span className={styles.smbr}>
                  <br />
                </span>
                <span className={styles.adjustTitle}>{t('ご案内致します')}</span>
              </div>
              <div className={styles.text}>
                {t(
                  '累計で100社以上のスタートアップの資金調達を成功に導いてきた実績を持つ、資金調達のプロフェッショナル集団が直接面談を担当いたします。ご利用方法や料金体系についても、豊富な経験を持つメンバーが丁寧にご案内しますので、どうぞ安心してお任せください。'
                )}
              </div>
            </div>
            <div>
              <Button2
                componentType='a'
                hierarchy='primary'
                size='xxl'
                href={sanitizeUrl(`${process.env.NEXT_PUBLIC_CONTACT_URL}`)}
                anchorTarget='_blank'
              >
                {t('担当者との面談を申し込む')}
              </Button2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
