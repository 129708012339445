import { FC } from 'react'
import { sanitizeUrl } from '@/utils'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'

export const GlobalFooter: FC = () => {
  const { t } = useTranslation(['global'])
  return (
    <footer className={styles.footer}>
      <div className='container'>
        {/* <a
          className={styles.item}
          href={sanitizeUrl(`${process.env.NEXT_PUBLIC_INVESTOR_FIND_PDF_URL}`)}
          target='_blank'
          rel='noreferrer'
        >
          About
        </a> */}
        <Link key='terms' href={sanitizeUrl('/terms')}>
          <a className={styles.item}>{t('利用規約')}</a>
        </Link>
        <a
          className={styles.item}
          href={sanitizeUrl(`${process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}`)}
          target='_blank'
          rel='noreferrer'
        >
          {t('プライバシーポリシー')}
        </a>
        <Link key='terms' href={sanitizeUrl('/scta')}>
          <a className={styles.item}>{t('特定商取引法に基づく表記')}</a>
        </Link>
        <div className={styles.bottom}>
          <div className={styles.bottom__copyright}>
            <p>&copy; {new Date().getFullYear()} PROTOCOL, Inc. All rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
