import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { Button2 } from '@/components/atoms/Button2'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

export const TopPageHeroTopEn: FC = observer(() => {
  const { t } = useTranslation(['index'])

  return (
    <div className={styles.container}>
      <div className='container'>
        <div className={styles.content}>
          <div className={styles.upper}>
            <div className={styles.upperItem}>
              <div className={styles.heading}>
                {t('最適な投資家との')}
                <span className={styles.smbr}>
                  <br />
                </span>
                {t('出会いを､最速で｡')}
              </div>
              <div className={styles.text}>
                {t('スタートアップに特化した、5,000社以上のVC、CVC、事業会社のデータベース。')}
                <span className={styles.lgbr}>
                  <br />
                </span>
                {t('投資ステージや業界情報など、必要な条件でフィルタリングし、')}
                <span className={styles.lgbr}>
                  <br />
                </span>
                {t('あなたのビジネスに最適な投資家をスムーズに見つけることができます。')}
              </div>
            </div>
            <div>
              <Button2
                componentType='a'
                hierarchy='primary'
                size='xxl'
                href={sanitizeUrl(`${process.env.NEXT_PUBLIC_CONTACT_URL}`)}
                anchorTarget='_blank'
              >
                {t('担当者との面談を申し込む')}
              </Button2>
            </div>
          </div>
          <div className={styles.lower}>
            <div>
              <img className={styles.img} src='/images/common/home/macbook-mockup-en.png' alt='macbook mock' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
